.intro {
	height: 100%;
}
.overlay {
	height: 100%;
}
#rules {
	width: 100%;
	background-image: url('../public/img/wedding/restik2.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover; /* Optional: to cover the entire area */
}

/* #portfolio {
	
} */
@media screen and (max-width: 400px) {
	#about,
	#services,
	#testimonials,
	#team,
	#contact,
	#features {
		width: 150%;
		min-height: 100svh;
	}

	#header {
		margin-top: 140px;
		min-height: 120svh;
		width: 150%;
	}
	.headerContent {
		height: 100%;
		margin-top: 150px;
	}
	#footer {
		min-height: 20svh;
	}
	#rules {
		width: 150%;
		background-position: 10% 50%;
	}
	#portfolio {
		width: 150%;
	}
}
@media screen and (min-width: 400px) and (max-width: 550px) {
	.portfolio-item {
		/* width: 60; */
	}
}

#services {
	background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('../public/img/wedding/percorso2.jpeg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background: blur(2px);
}
.portfolio-items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
}
.portfolio-item {
	-webkit-box-shadow: 0px 0px 14px 1px #07211f33;
	-moz-box-shadow: 0px 0px 14px 1px #07211f33;
	box-shadow: 0px 0px 14px 1px #07211f33;
	cursor: pointer;
	display: flex;
	margin: 2px;
	min-width: 300px;
	max-height: 260px;
	/* max-width: 500px; */
	max-width: 380px;
	border-radius: 3px;
	transition: all 200ms ease-in-out;
	@media screen and (min-width: 300px) and (max-width: 550px) {
		max-width: 350px;
	}
}
.portfolio-item:hover {
	scale: 1.05;
}
.forecast_cover {
	z-index: 20;
}
.weatherwidget-io {
	z-index: 10;
}
.service_img {
	display: flex;
	flex-direction: row;
	width: 100px;
	height: 100px;
	background-color: red;
	border-radius: 50%;
}
/* .col-md-4 {
	display: flex;
	flex-direction: column;
	align-items: center;
} */
.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* width: 100%; */
	flex-wrap: wrap;
}
h1,
h2 {
	font-family: 'HeadersCustomFont', sans-serif;
	text-transform: capitalize;
	font-weight: 300;
}
p,
span {
	font-family: 'RegularCustomFont', sans-serif;
}
#brand_id {
	/* font-family: 'RegularCustomFont', sans-serif; */
	font-family: 'HeadersCustomFont', sans-serif !important;
	text-transform: capitalize !important;
	font-size: 36px !important;
	font-weight: 300 !important;
}
/* .navbar-brand {
	text-transform: lowercase;
} */
.rowPlan {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	background: linear-gradient(to right, #091408 0%, #07211f 100%);
	border-radius: 5px;
	/* gap: 20px; */
}
.malyshka {
	width: 100%;
	display: flex;
	/* border: 1px solid blue; */
	/* border-radius: 5px; */
	align-items: center;
	gap: 30px;
	height: 250px;
	background: linear-gradient(to right, #091408 0%, #07211f 100%);
	color: white;
}
.time {
	display: flex;
	position: relative;
	align-items: center;
	height: 100%;
	padding: 20px;
	max-width: 160px;
	min-width: 160px;
	border-right: 1px solid white;
	justify-content: center;
	font-family: 'HeadersCustomFont', sans-serif !important;
	font-size: 20px;
	@media screen and (min-width: 390px) and (max-width: 430px) {
		min-width: 120px;
	}
}
.time::after {
	display: block;
	content: '';
	position: absolute;
	right: -4.5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: white;
}
.malyshka:nth-of-type(1) {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	@media screen and (max-width: 550px) {
		height: 450px;
	}
}
.sboku {
}
.planText {
	text-align: justify;
	padding-right: 30px;
	font-size: 12px;
	font-family: 'RegularCustomFont', sans-serif;
}

.malyshka:nth-of-type(2) {
	@media screen and (max-width: 550px) {
		height: 330px;
	}
}

.malyshka:nth-of-type(3) {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.planTitle {
	font-family: 'HeadersCustomFont', sans-serif !important;
	color: white;
	font-weight: 300;
	width: 30%;
	font-size: 32px;
}
@media screen and (min-width: 390) and (max-width: 550px) {
	.planText {
		font-size: 10px;
	}
}
@media (max-width: 767px) {
	.navbar-collapse {
		transition: height 0.3s ease, visibility 0.3s ease;
		overflow: hidden;
	}

	.navbar-collapse.collapse {
		height: 0;
		visibility: hidden;
	}

	.navbar-collapse.in {
		height: auto;
		visibility: visible;
	}
}

/* Обеспечиваем видимость меню на больших экранах */
@media (min-width: 768px) {
	.navbar-collapse {
		height: auto !important;
		visibility: visible !important;
		overflow: visible !important;
	}
}
.taxi {
	color: white;
	padding: 3px;
	border-radius: 5px;
	background: #f0f8ff42;
	display: inline-block; /* Строчно-блочный элемент */
	position: relative; /* Относительное позиционирование */
}

.taxi::after {
	content: attr(data-title); /* Выводим текст */
	position: absolute; /* Абсолютное позиционирование */
	left: 100%;
	width: 150px;
	bottom: 100%; /* Положение подсказки */
	z-index: 1; /* Отображаем подсказку поверх других элементов */
	background: rgba(23, 23, 23, 0.9); /* Полупрозрачный цвет фона */
	font-size: 11px; /* Размер текста подсказки */
	padding: 5px 10px; /* Поля */
	border-radius: 5px;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	color: white;
	border: 1px solid #333; /* Параметры рамки */
	opacity: 0; /* Изначально невидима */
	visibility: hidden; /* Изначально скрыта */
}

.taxi:hover::after {
	opacity: 1; /* При наведении видима */
	visibility: visible; /* При наведении отображается */
}
.rowContact {
	display: flex;
	margin: 20px 0px;
	flex-direction: column;
	align-items: stretch;
}
