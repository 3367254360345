.my-masonry-grid {
	display: flex;
	width: 100%;
}

.my-masonry-grid_column {
	background-clip: padding-box;
}

.my-masonry-grid_column > div {
	margin-bottom: 5px;
}
.rules {
	padding: 1rem; /* p-4 in Tailwind */
	overflow: hidden; /* overflow-clip in Tailwind */
}
.rule {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	width: 100%;
	align-items: center;
	justify-content: space-around;
}
.item {
	color: #ffffff; /* text-white */
	background: linear-gradient(to right, #091408 0%, #07211f 100%);
	font-family: 'HeadersCustomFont', sans-serif !important;
	border-radius: 5px;
	text-align: center; /* text-center */
	font-size: 4rem; /* text-6xl */
	width: 100%;
	line-height: auto !important;
}
.flex {
	display: flex;
	gap: 8px;
	flex-direction: row;
	align-items: flex-start;
}
.ruleItem {
	width: 100%;
}
.itemText {
	-webkit-box-shadow: 0px 0px 14px 1px #07211f33;
	-moz-box-shadow: 0px 0px 14px 1px #07211f33;
	box-shadow: 0px 0px 14px 1px #07211f33;
	display: flex;
	padding: 10px;
	word-break: revert-layer;
	/* word-wrap: break-word; */
	height: 100%;
	text-wrap: balance;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.itemTextH {
	font-family: 'HeadersCustomFont', sans-serif !important;
	font-size: 24px;
}
.itemTextP {
	font-size: 12px;
}
.customRuleH {
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	text-align: center;
}
.customRuleH2::after {
	position: absolute;
	content: '';
	background: linear-gradient(to right, #308229 0%, #07211f 100%);
	height: 4px;
	width: 60px;
	border-radius: 1px;
	top: 70%;
	margin-left: -30px;
	left: 50%;
}
.containerCustom {
}
.containerCustom {
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 768px) {
	.containerCustom {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.containerCustom {
		width: 970px;
	}
}
